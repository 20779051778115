import Vue from "vue";
import VueRouter from "vue-router";
import privacy from "../views/minimalist/privacy";
import terms from "../views/adlocked/terms";
// import home from "../views/jibjab/home";
import home from "../views/plantstory/home";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home
  }, {
    path: "/about-privacy",
    name: "privacy",
    component: privacy
  }, {
    path: "/about-terms",
    name: "terms",
    component: terms
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
