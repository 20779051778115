import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import "@/style/idec/font.css";
// import "@/style/idec/style2.css";
// import "@/style/idec/ui.css";
// import "@/style/adlock/style.css";
// import "@/style/adlock/queries.css";
// import "@/style/minimalist/jquery.fullPage.css";
// import "@/style/minimalist/style.css";
import "@/style/plantstory/main.css";
Vue.config.productionTip = false;


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
